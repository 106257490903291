/* eslint-disable no-unused-vars */
<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="d-flex">
      <h1 class="title mt-0 fs-b fs-2500 color-pr">
        Gestion des informations personnelles
      </h1>
    </div>
    <div class="fr-container w-gdc">
      <div class="coordonnees-bloc">
        <h2>Informations générales</h2>

        <!-- Nom Prenom -->
        <div class="row" v-if="!editNomPrenom">
          <p class="col text">Nom Prénom</p>
          <p class="col">{{ nom + ' ' + prenom }}</p>
          <div class="col button-edit">
            <button
              id="edit-nom-prenom"
              class="ri-edit-2-line"
              @click="modificationNomPrenom()"
            >
              <span class="sr-only">Modifier Nom Prénom</span>
            </button>
          </div>
          <div
            v-if="msgSuccessNomPrenom && msgSuccessNomPrenom.length !== 0"
            class="fr-alert fr-alert--success"
          >
            <p class="fr-alert__title">{{ msgSuccessNomPrenom }}</p>
          </div>
        </div>
        <div class="bloc" v-if="editNomPrenom">
          <label class="col text" for="nom">Nom</label>
          <input
            id="nom"
            type="text"
            class="col fr-input"
            v-model="nom"
            @input="validateNom(nom)"
          />
        </div>
        <div
          v-if="editNomPrenom && msg.nom"
          class="fr-alert fr-alert--error ri-alert-line"
        >
          <p class="fr-alert__title">{{ msg.nom }}</p>
        </div>
        <div class="bloc" v-if="editNomPrenom">
          <label class="col text" for="prénom">Prénom</label>
          <input
            id="prenom"
            type="text"
            class="col fr-input"
            v-model="prenom"
            @input="validatePrenom(prenom)"
          />
        </div>
        <div
          v-if="editNomPrenom && msg.prenom"
          class="fr-alert fr-alert--error ri-alert-line"
        >
          <p class="fr-alert__title">{{ msg.prenom }}</p>
        </div>
        <div class="bloc decalage" v-if="editNomPrenom">
          <button @click="sauvegarderNomPrenom(nom, prenom)" class="btn">
            Enregistrer
          </button>
          <button class="btn-annuler" @click="annulerNomPrenom()">
            Annuler
          </button>
        </div>
        <!-- Nom Prenom -->
      </div>

      <div class="coordonnees-bloc">
        <h2>Coordonnées</h2>

        <!-- Email -->
        <div class="row trait" v-if="!editEmail">
          <p class="col text">Adresse courriel</p>
          <p class="col">{{ email }}</p>
          <div class="col button-edit">
            <button
              id="edit-email"
              class="ri-edit-2-line"
              @click="modificationEmail()"
            >
              <span class="sr-only">Modifier Adresse courriel</span>
            </button>
          </div>
          <div
            v-if="msgSuccessMail && msgSuccessMail.length !== 0"
            class="fr-alert fr-alert--success"
          >
            <p class="fr-alert__title">{{ msgSuccessMail }}</p>
          </div>
        </div>
        <div class="bloc" v-if="editEmail">
          <label class="col text" for="email">Adresse courriel</label>
          <input
            id="email"
            type="email"
            class="col fr-input"
            v-model="email"
            @input="validateEmail(email)"
          />
        </div>
        <div
          v-if="editEmail && msg.email"
          class="fr-alert fr-alert--error ri-alert-line"
        >
          <p class="fr-alert__title">{{ msg.email }}</p>
        </div>
        <div class="bloc decalage" v-if="editEmail">
          <button @click="sauvegarderEmail(email)" class="btn">
            Enregistrer
          </button>
          <button class="btn-annuler" @click="annulerEmail(email)">
            Annuler
          </button>
        </div>
        <!-- Email -->

        <!-- Telephone mobile -->
        <div class="bloc" v-if="!editMobile">
          <p class="col text">Téléphone mobile</p>
          <p class="col">{{ formattedMobile() }}</p>
          <div class="col button-edit">
            <button
              id="edit-tel-mobile"
              class="ri-edit-2-line"
              @click="modificationMobile()"
            >
              <span class="sr-only">Modifier Téléphone mobile</span>
            </button>
          </div>
        </div>
        <div
          v-if="msgSuccessMobile && msgSuccessMobile.length !== 0"
          class="fr-alert fr-alert--success"
        >
          <p class="fr-alert__title">{{ msgSuccessMobile }}</p>
        </div>
        <TelephoneMobile
          v-if="editMobile"
          :realPhone="this.mobileNotHide"
        ></TelephoneMobile>
      </div>
      <!-- Telephone mobile -->

      <div class="statuts-bloc">
        <h2>Statuts</h2>

        <!-- Tiers déclarant -->
        <div class="row" v-if="!editTiersDeclarant">
          <p class="col text">Tiers déclarant</p>
          <p class="col">{{ tiersDeclarant }}</p>
          <div class="col button-edit">
            <button
              id="edit-tiers-declarant"
              class="ri-edit-2-line"
              @click="modificationTiersDeclarant()"
            >
              <span class="sr-only">Modifier Tiers déclarant</span>
            </button>
          </div>
        </div>
        <div
          v-if="
            msgSuccessTiersDeclarant && msgSuccessTiersDeclarant.length !== 0
          "
          class="fr-alert fr-alert--success"
        >
          <p class="fr-alert__title">{{ msgSuccessTiersDeclarant }}</p>
        </div>

        <div class="bloc" v-if="editTiersDeclarant">
          <p class="col text fr-toggle__label">Tiers déclarant</p>
          <div class="fr-radio-group">
            <input
              type="radio"
              id="tierDeclarant-true"
              v-model="tiersDeclarant"
              value="Oui"
            />
            <label
              :class="{
                'fr-label-checked': tiersDeclarant === 'Oui',
                'fr-label': tiersDeclarant !== 'Oui'
              }"
              class="fr-label"
              for="tierDeclarant-true"
              >Oui
            </label>
            <input
              type="radio"
              id="tierDeclarant-false"
              value="Non"
              v-model="tiersDeclarant"
            />
            <label
              :class="{
                'fr-label-checked': tiersDeclarant !== 'Non',
                'fr-label': tiersDeclarant === 'Non'
              }"
              class="fr-label"
              for="tierDeclarant-false"
              >Non
            </label>
          </div>
        </div>
        <div
          v-if="editTiersDeclarant && msg.tiersDeclarant"
          class="fr-alert fr-alert--error ri-alert-line"
        >
          <p class="fr-alert__title">{{ msg.tiersDeclarant }}</p>
        </div>
        <div class="bloc decalage" v-if="editTiersDeclarant">
          <button
            id="tierDeclarant-enregistrer"
            @click="sauvegarderTiersDeclarant(tiersDeclarant)"
            class="btn"
          >
            Enregistrer
          </button>
          <button
            id="tierDeclarant-annuler"
            class="btn-annuler"
            @click="annulerTiersDeclarant()"
          >
            Annuler
          </button>
        </div>
        <!-- Tiers déclarant -->
      </div>

      <div class="sous-container">
        <div class="col password-bloc">
          <div class="password-line">
            <div class="col">
              <h2>Mot de passe</h2>
              <p class="col pass">••••••••••</p>
            </div>
            <div class="col logo-password">
              <img
                src="../assets/img/shield.svg"
                class="logo-pass"
                alt="icone d'un cadenas avec un mot de passe devant"
              />
            </div>
          </div>
          <div class="trait2">
            <button
              id="edit-mot-de-passe"
              class="ri-edit-2-line"
              @click="modificationMotDePasse()"
            >
              Modifier le mot de passe
            </button>
            <div
              v-if="msgSuccessPassword && msgSuccessPassword.length !== 0"
              class="fr-alert fr-alert--success mt-1 mb-2"
            >
              <p class="fr-alert__title">{{ msgSuccessPassword }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="coordonnees-bloc" v-if="isSessionsActivesAffiche()">
        <div class="bloc-haut">
          <h2 class="col">Sessions actives</h2>
          <!-- <button class="col ri-shut-down-line" @click="revokeSessions()">
            Clôturer session
          </button> -->
        </div>

        <div class="table-sessions">
          <div class="card-container" v-if="sessions && sessions.length != 0">
            <div
              class="card-session col"
              v-for="session in sessionsFiltred"
              :key="session.id"
            >
              <p class="col text">Dernier accès :</p>
              <p class="col text">
                {{ getDate(session.loginTime) }}
              </p>
              <p class="col text-card">Navigateur</p>
              <p class="col">{{ getNavigator(session.userAgent) }}</p>
              <p class="col text-card">Système d'exploitation</p>
              <p class="col">{{ getSystem(session.userAgent) }}</p>
              <p class="col text-card">Adresse IP</p>
              <p class="col">{{ session.ip }}</p>
              <button
                class="ri-shut-down-line trait2"
                @click="revokeSessionById(session.id)"
              >
                Clôturer session
              </button>
            </div>
          </div>
        </div>

        <div
          class="pagination-sessions"
          v-if="sessions && sessions.length != 0"
        >
          <nav
            v-if="this.sessions.length > 10"
            class="rf-pagination justify-content-center"
            aria-label="Pagination navigation"
          >
            <ul class="rf-pagination__list">
              <li
                class="rf-pagination__item--prev rf-pagination__item"
                :class="{
                  'rf-pagination__item--disabled': currentPage === 1
                }"
              >
                <a
                  href="#"
                  class="rf-pagination__link pointer"
                  title="Page précédente"
                  @click="prevPage($event)"
                >
                  <span class="rf-pagination__label">Précédente</span>
                </a>
              </li>
              <li
                class="rf-pagination__item"
                v-for="(numeroPage, index) in nbPage <= nbPageWithoutTrunc
                  ? nbPage
                  : nbPageBeforeDivide"
                :key="`page-${index}`"
              >
                <a
                  href="#"
                  class="rf-pagination__link"
                  @click="
                    $event.preventDefault();
                    currentPage = numeroPage;
                  "
                  :title="'Page ' + numeroPage"
                  :class="{
                    'rf-pagination__item--active': currentPage === numeroPage
                  }"
                >
                  {{ numeroPage }}
                </a>
              </li>
              <li
                class="rf-pagination__item rf-pagination__item--from-md"
                v-if="nbPage > nbPageWithoutTrunc"
              >
                <a
                  v-if="
                    nbPage - nbPageBeforeDivide < currentPage ||
                    currentPage <= nbPageBeforeDivide
                  "
                  class="rf-pagination__link"
                  @click="currentPage = numeroPage"
                  :title="'Page ' + numeroPage"
                >
                  ...
                </a>
                <a
                  v-else
                  class="rf-pagination__link rf-pagination__item--active"
                  @click="currentPage = numeroPage"
                  :title="'Page ' + numeroPage"
                >
                  {{ currentPage }}
                </a>
              </li>
              <li
                class="rf-pagination__item"
                :style="nbPage <= nbPageWithoutTrunc ? 'display:none;' : ''"
                v-for="(numeroPage, index) in [nbPage - 2, nbPage - 1, nbPage]"
                :key="index"
              >
                <a
                  v-if="nbPage > nbPageWithoutTrunc"
                  class="rf-pagination__link"
                  @click="currentPage = numeroPage"
                  :title="'Page ' + numeroPage"
                  :class="{
                    'rf-pagination__item--active': currentPage === numeroPage
                  }"
                >
                  {{ numeroPage }}
                </a>
              </li>

              <li
                class="rf-pagination__item--next rf-pagination__item"
                :class="{
                  'rf-pagination__item--disabled': currentPage === nbPage
                }"
              >
                <a
                  href="#"
                  class="rf-pagination__link pointer"
                  title="Page suivante"
                  @click="nextPage($event)"
                >
                  <span class="rf-pagination__label">Suivante</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="sous-container">
        <div class="col supprimer-compte-bloc">
          <button class="btn-compte" @click="exporterProfil()">
            <span
              role="img"
              aria-label="logo flèche vers le bas pour exporter le profil"
              class="ri-download-fill"
            ></span
            >Exporter le profil
          </button>
          <button class="btn-compte" @click="supprimerCompte()">
            <span
              role="img"
              aria-label="logo poubelle pour supprimer le compte"
              class="ri-delete-bin-line"
            ></span
            >Supprimer mon compte
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import store from '../store';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { deleteUser } from '../services/NoBusinessService';
import {
  downloadObjectAsJson,
  getHabilitationSirenErrorMessage
} from '../utils/methodsUtils';
import TelephoneMobile from '../components/TelephoneMobile.vue';
export default {
  components: {
    TelephoneMobile
  },
  data() {
    return {
      show: process.env.VUE_APP_FONCTIONNALITE_ADRESSE === 'true',
      fullname: store.state.user.fullname,
      regexNomCommun: /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/,
      nom: '',
      prenom: '',
      email: '',
      lastModified: '',
      mobile: '',
      mobileNotHide: '',
      tiersDeclarant: '',
      ip: '',
      lastAccessTime: '',
      navigateur: '',
      system: '',
      editNomPrenom: false,
      editEmail: false,
      editMobile: false,
      editTiersDeclarant: false,
      userInfo: '',
      pays: '',
      sessions: [],
      msgSuccessNomPrenom: '',
      msgSuccessMail: '',
      msgSuccessTiersDeclarant: '',
      msgSuccessMobile: '',
      msgSuccessPassword: '',
      pageSize: 6,
      currentPage: 1,
      nbPageWithoutTrunc: 6,
      nbPageBeforeDivide: 3,
      nbPage: 1,
      numeroPage: 1,
      msg: {
        email: '',
        nom: '',
        prenom: '',
        tiersDeclarant: ''
      }
    };
  },
  created() {
    this.initialiserUserInfo();
  },
  mounted() {
    this.emitter.on('TelephoneMobile-close', () => {
      this.modificationMobile();
    });
    this.emitter.on('popup-confirmation-telephone-portable-validate', () => {
      this.msgSuccessMobile =
        'Le changement du numéro de portable a bien été enregistré.';
      this.emitter.emit('TelephoneMobile-close');
      this.initialiserUserInfo();
    });
    if (this.$route.query?.source) {
      if (this.$route.query?.source === 'mobile') {
        this.msgSuccessMobile = this.$route.query?.html;
      } else if (this.$route.query?.source === 'password') {
        this.msgSuccessPassword = this.$route.query?.html;
      }
      console.log(this.msgSuccessPassword);
      this.$router.replace({});
    }
  },
  computed: {
    sessionsFiltred() {
      let newList = [];
      if (this.sessions && this.sessions.length !== 0) {
        newList = this.sessions;

        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;

        newList = newList.slice(start, end);
      }

      return newList;
    }
  },
  methods: {
    formattedMobile() {
      return (
        this.mobile[0] +
        this.mobile[1] +
        ' ' +
        this.mobile[2] +
        this.mobile[3] +
        ' ' +
        this.mobile[4] +
        this.mobile[5] +
        ' ' +
        this.mobile[6] +
        this.mobile[7] +
        ' ' +
        this.mobile[8] +
        this.mobile[9]
      );
    },
    setNbPage(itemList) {
      if (itemList && itemList.length != 0) {
        let modulo = itemList.length % this.pageSize;
        let result = itemList.length / this.pageSize;
        let nombrePage = 0;
        if (modulo === 0) {
          nombrePage = result;
        } else {
          nombrePage = Math.ceil(result);
        }
        this.nbPage = nombrePage;
      }
    },
    nextPage(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.currentPage * this.pageSize < this.sessions.length) {
        this.currentPage++;
      }
    },
    prevPage(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    initialiserUserInfo() {
      this.$informationPersonnellesService
        .getPersonalInfo()
        .then((response) => {
          this.userInfo = response.data.resultat;

          this.email = this.userInfo.emails[0];
          this.nom = this.userInfo.name?.familyName;
          this.prenom = this.userInfo.name?.givenName;
          this.lastModified = this.userInfo.meta?.lastModified
            ? format(new Date(this.userInfo.meta.lastModified), 'dd MMM. yyyy')
            : undefined;
          this.userInfo.phoneNumbers?.forEach((phone) => {
            if (phone.type === 'mobile') {
              this.mobileNotHide = phone.value;
              this.mobile = '******' + phone.value.slice(-4);
            }
          });
          this.tiersDeclarant =
            this.userInfo[
              'urn:ietf:params:scim:schemas:extension:enterprise:2.0:User'
            ].tiers_declarant === true
              ? 'Oui'
              : 'Non';
        });
      this.$informationPersonnellesService.getSessions().then((response) => {
        if (
          response.data &&
          response.data.resultat &&
          response.data.resultat.sessions
        ) {
          this.sessions = response.data.resultat.sessions;
          this.setNbPage(this.sessions);
        }
      });
    },
    getDate(timestamp) {
      return timestamp
        ? format(new Date(new Number(timestamp)), 'dd LLLL yyyy HH:mm', {
            locale: fr
          })
        : undefined;
    },
    getSystem(nAgt) {
      var os;
      var clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        {
          s: 'Windows NT 4.0',
          r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
        },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        {
          s: 'Search Bot',
          r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
        }
      ];
      for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }

      var osVersion = undefined;

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
      }

      switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
          // eslint-disable-next-line no-useless-escape
          osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
            nAgt
          )[1];
          break;

        case 'iOS':
          if (/iPhone OS/.test(nAgt)) {
            osVersion = /iPhone OS (.*)/.exec(nAgt)[1];
          }
          osVersion = osVersion.split(' ')[0];
          break;
      }
      return (os ? os + ' ' : '') + (osVersion ? osVersion : '');
    },
    getNavigator(nAgt) {
      var nameOffset, verOffset, ix, browser, version, majorVersion;

      // Opera
      if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf('OPR')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
      }
      // Legacy Edge
      else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
      }
      // Edge (Chromium)
      else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf('Trident/') != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
      }
      // Other browsers
      else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
      ) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
        }
      }
      // trim the version string
      if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

      majorVersion = parseInt('' + version, 10);
      if (isNaN(majorVersion)) {
        version = '' + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      return (browser ? browser + ' ' : '') + (version ? version : '');
    },
    revokeSessions() {
      this.$informationPersonnellesService.deleteSessions().then(() => {
        this.initialiserUserInfo();
      });
    },
    revokeSessionById(id) {
      const html = `<p class="fs-875">Cette action vous déconnectera de la session active sur cet appareil. Souhaitez-vous continuer ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          this.$informationPersonnellesService
            .deleteSessionById(id)
            .then(() => {
              this.initialiserUserInfo();
              this.$auth.loginAfterSessionRevoke();
            })
            .catch((err) => {
              console.error('Une erreur est surevenue', err);
            });
        }
      });
    },
    async sauvegarderEmail(email) {
      if (this.validateEmail(email)) {
        await this.$informationPersonnellesService
          .sauvegarderEmail(email)
          .then(async () => {
            this.msgSuccessMail =
              "Veuillez consulter vos courriels afin de valider votre nouvelle adresse. Les détails du profil seront mis à jour à l'issue de cette validation.";
            await this.initialiserUserInfo();
            this.modificationEmail();
          })
          .catch((err) => {
            if (
              err.response.status === 400 &&
              err?.response?.data?.detail.includes(
                '30004 - UserAlreadyExisting'
              )
            ) {
              this.msg.email =
                'Cette adresse courriel est déjà rattachée à un compte Portailpro, veuillez en saisir une nouvelle.';
            } else {
              this.msg.email = err?.response?.data?.detail;
            }
          });
      } else {
        // afficher erreur
      }
    },
    validateEmail(email) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        this.msg.email = '';
        return true;
      } else {
        this.msg.email =
          "L'adresse courriel n'est pas au bon format : exemple@domaine.com";
        return false;
      }
    },
    validateNom(nom) {
      if (nom && nom.length != 0) {
        if (this.regexNomCommun.test(nom)) {
          this.msg.nom = '';
          return true;
        } else {
          this.msg.nom = "Le nom n'est pas au bon format.";
        }
      } else {
        this.msg.nom = 'Ce champ est obligatoire';
      }
      return false;
    },
    validatePrenom(prenom) {
      if (prenom && prenom.length != 0) {
        if (this.regexNomCommun.test(prenom)) {
          this.msg.prenom = '';
          return true;
        } else {
          this.msg.prenom = "Le prénom n'est pas au bon format.";
        }
      } else {
        this.msg.prenom = 'Ce champ est obligatoire';
      }
      return false;
    },
    async sauvegarderTelephoneFixe(telephoneFixe) {
      if (this.validateTelephoneFixe(telephoneFixe)) {
        await this.$informationPersonnellesService
          .sauvegarderTelephoneFixe(telephoneFixe)
          .then(async () => {
            this.msgSuccessFixe =
              'Le changement du numéro de téléphone fixe a bien été enregistré.';
            await this.initialiserUserInfo();
            this.modificationFixe();
          })
          .catch((err) => {
            console.log('Une erreur est surevenue', err);
          });
      } else {
        // afficher erreur
      }
    },
    sauvegarderNomPrenom(nom, prenom) {
      if (this.validateNom(nom) && this.validatePrenom(prenom)) {
        const response1 = this.$informationPersonnellesService.sauvegarderNom(
          nom
        );
        const response2 = this.$informationPersonnellesService.sauvegarderPrenom(
          prenom
        );
        Promise.all([response1, response2])
          .then(async (data) => {
            if (data[0].status == 200 && data[1].status == 200) {
              this.msgSuccessNomPrenom = 'Le changement a bien été enregistré.';
              await this.initialiserUserInfo();
              this.modificationNomPrenom();
            }
          })
          .catch((err) => {
            console.log('Une erreur est surevenue : ', err);
          });
      }
    },
    async sauvegarderTiersDeclarant() {
      await this.$informationPersonnellesService
        .sauvegarderTiersDeclarant(this.tiersDeclarant === 'Oui' ? true : false)
        .then(async () => {
          this.msgSuccessTiersDeclarant =
            'Le changement de votre statut a bien été enregistré.';
          await this.initialiserUserInfo();
          this.modificationTiersDeclarant();
        })
        .catch((err) => {
          console.log('Une erreur est surevenue', err);
        });
    },
    modificationMobile() {
      this.editMobile = !this.editMobile;
      if (this.editMobile) {
        this.msg.mobile = undefined;
        this.msgSuccessMobile = undefined;
      }
    },
    modificationMotDePasse() {
      this.$router.push({ name: 'Modification mot de passe' });
    },
    modificationEmail() {
      this.editEmail = !this.editEmail;
      if (this.editEmail) {
        this.msg.email = undefined;
        this.msgSuccessMail = undefined;
      }
    },
    annulerEmail() {
      const html = `<p class="fs-875">Etes-vous sûr de vouloir abandonner le changement ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          this.modificationEmail();
          await this.initialiserUserInfo();
        }
      });
    },
    annulerTiersDeclarant() {
      const html = `<p class="fs-875">Etes-vous sûr de vouloir abandonner le changement ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          this.modificationTiersDeclarant();
          await this.initialiserUserInfo();
        }
      });
    },
    annulerNomPrenom() {
      const html = `<p class="fs-875">Etes-vous sûr de vouloir abandonner le changement ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', async (payload) => {
        if (payload === 'oui') {
          this.modificationNomPrenom();
          await this.initialiserUserInfo();
        }
      });
    },
    modificationNomPrenom() {
      this.editNomPrenom = !this.editNomPrenom;
    },
    modificationTiersDeclarant() {
      this.editTiersDeclarant = !this.editTiersDeclarant;
    },
    supprimerCompte() {
      this.controleRoleAge().then((res) => {
        const isAgeKO = res;

        const hasRattachement = store.getters.comptesRattachees
          ? Object.keys(store.getters.comptesRattachees).length !== 0
          : false;

        if (!isAgeKO && !hasRattachement) {
          const html = `<p class="fs-875">Confirmez-vous la suppression de votre compte Portailpro.gouv ?</p>`;
          this.setContent({ html, action: 'confirm' });
          this.showPopup();
          this.emitter.once('popup', (payload) => {
            if (payload === 'oui') {
              deleteUser().then((reponse) => {
                console.log(reponse);
                if (reponse.data.resultat === 204) {
                  setTimeout(() => {
                    const html = `<p class="fs-875">Votre compte a bien été supprimé.</p>`;
                    this.setContent({ html, action: 'info' });
                    this.showPopup();
                    this.emitter.once('popup', (payload) => {
                      if (payload === 'ok') {
                        localStorage.clear();
                        store.dispatch('resetState');
                        const url = process.env.VUE_APP_DNS_PCR_SNA;
                        window.open(url, '_self');
                      }
                    });
                  }, 400);
                } else {
                  console.log(reponse); // afficher erreur
                }
              });
            }
          });
        } else {
          if (hasRattachement) {
            const html = `<p class="fs-875">Tant qu'au moins un rattachement existe, la suppression du compte ne sera pas possible.</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }
          if (isAgeKO) {
            const html = `<p class="fs-875">La suppression du compte ne peut pas être réalisé, veuillez vous assurer qu'il reste au moins un AGE dans cette entreprise</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }
        }
      });
    },
    async controleRoleAge() {
      let isAgeKO = false;
      const sirenRequest = {
        page: 1,
        size: 10,
        filter: 'siren'
      };
      await this.$habilitationService
        .getSirenList(sirenRequest)
        .then(async (result) => {
          const sirens = result.data?.sirens;
          if (sirens.length !== 0) {
            for (const obj of sirens) {
              await this.$rolesService
                .getAGEBySiren(obj.siren)
                .then((resultat) => {
                  if (
                    resultat.data.length == 1 &&
                    resultat.data[0] == store.state.user.idPCR
                  ) {
                    //Il existe un seul age et c'est l'utilisateur en cours => on bloque la suppression
                    isAgeKO = true;
                    return;
                  } else if (resultat.data.length == 0) {
                    //Il n'existe aucun AGE pour ce siren => cas normalement impossible mais on bloque la suppression
                    console.log(
                      "Il n'existe pas d'age pour ce siren : ",
                      obj.siren
                    );
                    isAgeKO = true;
                    return;
                  }
                })
                .catch((err) => {
                  //Afficher erreur
                  console.error(err);
                  isAgeKO = true;
                  return; // Erreur lors de l'appel des age par siren
                });
            }
          }
        })
        .catch((err) => {
          isAgeKO = true;
          const errorMessage = getHabilitationSirenErrorMessage(
            err?.response?.data
          );
          if (errorMessage) {
            const html = `<p class="fs-875">${errorMessage}</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
          }
        });

      return isAgeKO;
    },
    exporterProfil() {
      this.$informationPersonnellesService
        .getExportProfil()
        .then((res) => {
          downloadObjectAsJson(res.data, 'user-profil');
        })
        .catch((err) => {
          console.error(err);
        });
    },
    isSessionsActivesAffiche() {
      return process.env.VUE_APP_SESSIONS_ACTIVES === 'true';
    },
    ...mapActions(['showPopup', 'setContent', 'setAGE', 'setPreviousPath'])
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: $base-color;
}

h2 {
  font-size: 20px;
}

.w-gdc {
  //background-color: aqua;
  width: 60%;
}

.rf-fi-user-line::before {
  font-size: 33px;
  border-radius: 50%;
  box-shadow: 0 0 2px #888;
  padding: 0.3em 0.3em;
}

.rf-fi-user-line {
  font-size: 33px;
  padding: 2%;
}

.sous-container {
  width: 103%;
  padding-left: 1%;
  display: flex;
}

.coordonnees-bloc {
  width: 100%;
  padding: 3%;
  padding-right: 2%;
  border: 1px solid #dddddd;
  margin: 2%;
  padding-bottom: 1%;
}

.adresse-bloc {
  width: 100%;
  padding: 3%;
  padding-right: 2%;
  border: 1px solid #dddddd;
  margin: 2%;
  padding-bottom: 1%;
}

.statuts-bloc {
  width: 100%;
  padding: 3%;
  padding-right: 2%;
  border: 1px solid #dddddd;
  margin: 2%;
  padding-bottom: 1%;
}

.password-bloc {
  width: 100%;
  padding: 3%;
  border: 1px solid #dddddd;
  margin: 1%;
  padding-bottom: 0%;
}

.supprimer-compte-bloc {
  width: 100%;
  padding-top: 7%;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}

.text {
  color: #6a6a6a;
  font-size: 13px;
}

.text-card {
  color: #6a6a6a;
  font-size: 13px;
  padding-top: 1rem;
}

.pass {
  font-size: 13px;
  margin-bottom: 2.5rem;
}

.row {
  width: auto;
  padding: 1rem;
}

.trait {
  border-bottom: 1px solid #ddd;
}

.trait2 {
  border-top: 1px solid #ddd;
  width: 105%;
  margin-left: -3%;
}

.bloc {
  width: auto;
  display: flex;
  padding: 1rem;
}

.password-line {
  width: auto;
  display: flex;
}

.password-line-end {
  width: auto;
  display: flex;
  margin-bottom: 1rem;
}

.button-edit {
  display: flex;
  justify-content: flex-end;
}

.ri-edit-2-line {
  background: white;
  color: #000091;
  border: none;
  padding: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 1%;
}

.bloc-haut {
  display: flex;
}

.ri-shut-down-line {
  background: white;
  color: #000091;
  border: none;
  padding-bottom: 5%;
  text-align: end;
}

.btn-annuler {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 14px;
  width: 100%;
  margin-left: 4rem;
  margin-right: 1rem;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.btn:hover {
  background-color: #1212ff;
}

.btn-compte {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 18px;
  width: 40%;
  padding: 0.5rem 1rem;
  margin-left: 4%;
}

.ri-delete-bin-line {
  padding-right: 3%;
}

.ri-download-fill {
  padding-right: 3%;
}

.form-error {
  white-space: normal;
  background: #ffffff !important;
  color: #9f3a38 !important;
  margin-left: 50%;
}

.fr-alert {
  margin-left: 2%;
  margin-right: 2%;
  width: 95%;
}

.fr-label {
  margin-left: 4rem !important;
}

.fr-radio-group {
  display: flex;
}

.fr-alert--success::before {
  content: close-quote;
  filter: unset;
}

.rf-pagination__link:not(:disabled):hover {
  text-decoration: underline !important;
}

.rf-pagination {
  width: max-content;
  margin: auto;
}

.rf-pagination__item--active {
  background-color: $base-color;
  color: #fff;
}

.rf-pagination__item--prev > a,
.rf-pagination__item--next > a {
  font-size: 14px;
}

.ri-arrow-drop-down-line::before {
  top: 8px;
  left: auto;
  font-size: 26px;
  cursor: pointer;
}

.ri-arrow-drop-up-line::before {
  top: 8px;
  left: auto;
  font-size: 26px;
  cursor: pointer;
}

.logo-password {
  display: contents;
}

.logo-pass {
  padding-right: 1rem;
  filter: brightness(96%);
}

.card-session {
  border: 1px solid #dddddd;
  width: max-content;
  padding: 1.5rem;
  margin-bottom: 1rem;
  padding-bottom: 0;
}

.card-container {
  display: grid;
  grid-template-columns: 8rem 8rem 8rem;
  grid-column-gap: 6rem;
}

button.trait2 {
  border-top: 1px solid #ddd;
  text-align: center;
  margin-top: 1rem;
  background-color: transparent;
}

@media only screen and (max-width: 760px) {
  .w-gdc {
    width: 100%;
  }

  .btn-compte {
    width: 100%;
  }

  .card-session {
    width: 100%;
  }

  .card-container[data-v-36f6c470] {
    display: grid;
    grid-template-columns: none;
    grid-column-gap: none;
  }
}

@media only screen and (max-width: 339px) {
  .decalage {
    position: relative;
    right: 20px;
  }
}

@media only screen and (max-width: 360px) {
  .break_line {
    display: block;
  }

  .padding_b {
    padding-bottom: 1em;
  }
}
</style>
