<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="form-mobile flex flex-col">
      <label class="col text" for="new-number">Téléphone mobile</label>
      <InputTelephone
        :ancienNumero="realPhone"
        verifierAncienMobile="true"
        @input-tel-validation="getValidatedNum"
        type="MOBILE"
        :customErrorMessage="true"
      />
    </div>
    <div
      v-if="
        !validatedNum.isValid &&
        validatedNum.message &&
        validatedNum.message.length
      "
      class="fr-alert fr-alert--error ri-alert-line"
    >
      <p class="fr-alert__title">{{ validatedNum.message }}</p>
    </div>
    <div
      v-if="msgSuccess && msgSuccess.length !== 0"
      class="fr-alert fr-alert--success"
    >
      <p class="fr-alert__title">{{ msgSuccess }}</p>
    </div>

    <div class="form-button decalage">
      <button class="btn" @click="enregistrer()">Enregistrer</button>
      <button class="btn-annuler" @click="annuler()">Annuler</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InputTelephone from './InputTelephone.vue';
import store from '../store';

export default {
  name: 'TelephoneMobile',
  components: {
    InputTelephone
  },
  props: {
    realPhone: String
  },
  data() {
    return {
      validatedNum: { isValid: false, value: '', message: '' },
      fullname: store.state.user.fullname,
      email: store.state.user.email,

      msgErreurNew: '',
      msgSuccess: ''
    };
  },
  created() {},
  mounted() {},
  methods: {
    getValidatedNum(payload) {
      this.validatedNum = payload;
    },
    annuler() {
      const html = `<p class="fs-875">Etes-vous sûr de vouloir abandonner le changement ?</p>`;
      this.setContent({ html, action: 'confirm' });
      this.showPopup();
      this.emitter.once('popup', (payload) => {
        if (payload === 'oui') {
          this.validatedNum = { isValid: false, value: '', message: '' };
          this.emitter.emit('TelephoneMobile-close');
        }
      });
    },
    enregistrer() {
      if (this.validatedNum.isValid) {
        this.emitter.emit('popup-confirmation-telephone-portable-open');
        this.$informationPersonnellesService.sauvegarderTelephoneMobile(
          this.validatedNum.value.replaceAll(' ', '')
        );
      }
    },

    ...mapActions(['showPopup', 'setContent', 'setAGE', 'setPreviousPath'])
  }
};
</script>

<style lang="scss">
.ri-arrow-left-line {
  width: 14rem;
  color: black;
  cursor: pointer;
}

.form-mobile {
  width: auto;
  display: flex;
  padding: 1rem;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}
.btn-annuler {
  background: #ffffff;
  color: $base-color;
  border: $base-color solid 1px;
  font-size: 14px;
  width: 100%;
  margin-left: 4rem;
  margin-right: 1rem;
}
.btn:hover {
  background-color: #1212ff !important;
  color: white !important;
}

.form-button {
  width: auto;
  display: flex;
  padding: 1rem;
}

.fr-alert--error {
  width: 100%;
}

.fr-alert--success {
  width: 100%;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.ui .form input[type='tel'] {
  width: 357px;
  height: 40px;
  margin: 0px 0px 0px 0px;
  padding: 8px 16px 8px 16px;
  border-radius: 0.25rem 0.25rem 0px 0px !important;
  background-color: #f0f0f0;
  border-bottom: solid 2px #6a6a6a;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.text {
  color: #6a6a6a;
  font-size: 13px;
}
@media only screen and (max-width: 339px) {
  .decalage {
    position: relative;
    right: 20px;
  }
}
</style>
